<template>
  <div class="page-header">
    <slot name="header-breadcrumb">
      <el-breadcrumb separator="/" v-if="breadcrumb_list.length > 1">
        <el-breadcrumb-item v-for="(breadcrumb, index) in breadcrumb_list"
                            :key="index" :to="isLast(breadcrumb.path)">
          <span :style="{ color: index===(breadcrumb_list.length-1) ? '#18191A' : ''}">{{ breadcrumb.title }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </slot>
<!--    <slot name="header-title">-->
<!--      <div class="header-title">{{ title }}</div>-->
<!--    </slot>-->
    <slot name="header-subtitle">
      <p>{{ subtitle }}</p>
    </slot>
    <slot name="header-content"></slot>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: {
      type: String,
      default() {
        return this.$route.meta.title
      }
    },
    subtitle: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      breadcrumb_list: [],
    }
  },
  created() {
    const navMenu = this?.$route?.meta?.navMenu || []
    this.breadcrumb_list = []
    if (navMenu.length > 0) {
      this.breadcrumb_list.push(...navMenu)
      this.breadcrumb_list.push({
        title: this.$route.meta.title,
        path: '',
      })
    } else {
      this.defaultNavHandler()
    }
  },
  mounted() {

  },
  methods: {
    isLast(path){
      if (!path) {
        return ''
      }
      if (this.$route.path === path) {
        return ''
      }
      return path
    },
    /**
     * 默认面包屑处理
     */
    defaultNavHandler(){
      let that = this
      // let breadcrumb_list = [], parent = null
      // for (let matched of that.$route.matched) {
      //   if (matched.meta && matched.meta.title && matched.meta.title != parent) {
      //     breadcrumb_list.push(matched.meta.title)
      //     parent = matched.meta.title
      //   }
      // }
      // this.breadcrumb_list = [that.$route.meta.parent || '首页', that.$route.meta.title]
      //
      this.breadcrumb_list = [
        {
          title: that.$route.meta.parent || '首页',
          path: '',
        },
        {
          title: that.$route.meta.title,
          path: '',
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.page-header {
  padding: 24px 20px 10px;
  color: rgba(0, 0, 0, .85);
  font-size: 14px;
  line-height: 1.6;

  .header-title {
    margin: 12px 0 4px 0;
    color: rgba(0, 0, 0, .85);
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  }

  .header-subtitle {
    margin-top: 12px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 22px;
    min-height: 22px;
  }

  /deep/ .el-breadcrumb__inner, .el-breadcrumb__inner a{
    font-weight: unset;
    color: #8D9094;
  }
}
</style>
